import "lazysizes"
import "picturefill"
// import lpForm from './utill/lp-form'
// import svg4everybody from "svg4everybody"

// lpForm();

const scrollEvt = () => {
  const windowWidth = $(window).width();
  const windowSm = 768;
  const ua = navigator.userAgent;

  if (windowWidth > windowSm) {
    $(window).on("scroll", function() {
      if(ua.indexOf('Trident') === -1) {
        if ($(this).scrollTop() > 100 ) {

          $("header").css({
            "height": 75
          });
          $(".header__contact").css({
            "border-bottom": "75px solid #2B2F3B"
          });
          $(".header__contact__txt").css({
            "padding-top": 75
          });
        } else {
          $("header").css({
            "height": 100
          });
          $(".header__contact").css({
            "border-bottom": "100px solid #2B2F3B"
          });
          $(".header__contact__txt").css({
            "padding-top": 100
          });
        }
      }
    });    
  }
}
// 子パネル (li.cell) の数だけ空の子パネル (li.cell.is-empty) を追加する。
var $grid = $('.system__box__list'),   
emptyCells = [],
i;

for (i = 0; i < $grid.find('.system__box__item').length; i++) {
  emptyCells.push($('<div>', { class: 'system__box__item is-empty' }));
}
$grid.append(emptyCells);



//アコーディオン
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active")
    return false;
  });
});

//ヘッダーメニュー
$('.header__btn').on('click', function () {
  $('html').toggleClass('scroll-prevent')
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#nav-list').slideUp();
  } else {
    $(this).addClass('is-active');
    $('#nav-list').slideDown();
  }
})



const articles = $('.contentKv__mouse');

const triangle = []
for (i = 1; i < 13; i++) {
  triangle.push($(`.contentKv__triangle${i}`))
}


// カーソル移動中
articles.mousemove(function(e) {
  const current = $(this),
    // articleエリアにおける現在のカーソルのx座標 (原点=中心)
    x = current.width() - e.offsetX * 2,
    // articleエリアにおける現在のカーソルのy座標
    y = current.height() - e.offsetY * 2,
    // x軸方向の回転角度 (30は回転具合の調整用数値)
    rx = x / 30,
    // y軸方向の回転角度 (24は回転具合の調整用数値)
    ry = y / 30,
    test =  rx + ry * 1,
    test2 =  rx + ry * 3
  // .item-wrapperを5%拡大して、x軸、y軸方向に回転
  // current.css({"transform":"rotateY("+rx+"deg) rotateX("+ry+"deg)"});
  current.css({"transform":"translate("+rx+"px,"+ry+"px)"});

  for (i = 0; i < 12; i++) {
    if (i % 2 == 0) {
      triangle[i].css({"transform": "rotate("+test+"deg)"});
    }else{
      triangle[i].css({"transform": "rotate("+test2+"deg)"});
    }
  }
});




$(window).on('load',function(){
  // ここから文字を<span></span>で囲む記述
  $('.contentKv__head').children().andSelf().contents().each(function() {
  if (this.nodeType == 3) {
  $(this).replaceWith($(this).text().replace(/(\S)/g, '<span>$1</span>'));
  }
  });
  // ここから一文字ずつフェードインさせる記述
  $('.contentKv__head').css({'opacity':1});
  for (var i = 0; i <= $('.contentKv__head').children().size(); i++) {
  $('.contentKv__head').children('span:eq('+i+')').delay(100*i).animate({'opacity':1},50);
  };
});


//実績スライダー
$('.slick__list').slick({
  prevArrow: `<div class="slide-arrow prev-arrow"><img src="${path}/img/slick_prev.svg" alt=""></div>`,
  nextArrow: `<div class="slide-arrow next-arrow"><img src="${path}/img/slick_next.svg" alt=""></div>`,
  slidesToShow: 2,
  asNavFor:'.slick__list--left',
  responsive: [{
    breakpoint: 768,  //ブレイクポイントを指定
    settings: {
      slidesToShow: 1
    }    
  }],
});

//連携
$('.slick__list--left').slick({
  slidesToShow: 1,
  asNavFor:'.slick__list',
  fade: true,
});




(() => {
  scrollEvt()
  // headerBtn()
  // svg4everybody()
  // kvCursor()
  // headerMenu()
})()